@use "vars" as *;

/* ====================================================
Control
==================================================== */










.c-tx--v {
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-val{//vertical-align
	&--md{
		vertical-align: middle
	}
	&--top{
		vertical-align: top
	}
}





/* ====================================================
Component
==================================================== */
// Common Text style




// Common Title style
.c-ttl__{
	&04{
		font-family: $font-Sawarabi;
		@include fts(24, 48);
		font-weight: 400;
		color: #003D7D;
		@include mq{
			@include fts(16, 28);
		}
	}
}

// Common Button style
.c-btn__03{
	position: relative;
	display: inline-block;
	padding-right: 60px;
	
	@include p-after(40px, 6px){
		background: url(../img/rebuilding-donation/icon-arrow.png) no-repeat center/contain;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}
