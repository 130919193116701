@use "vars" as *;

/* ====================================================
HTML
==================================================== */

body{
	&.is-page-rebuilding{
		
		@include mq(md-min){
			overflow-x: unset;
		}
	}
}
