@use "vars" as *;


.sec-donation {
	color: black;

	.c-ttl__01 {
		font-family: $font-Sawarabi;
		color: black;

		@include mq {
			@include fts(18, 28);
		}
	}

	&__link {
		@include mq {
			opacity: 1;
			color: #003D7D;
		}

	}
}

.donation-top {
	padding: 54px 0 156px;

	@include mq {
		padding: 0 0 58px;
	}

	.c-ttl__01 {
		margin-bottom: 43px;

		@include mq {
			margin-bottom: 24px;
		}
	}

	&__flex {
		@include flex(between, 0, wrap);

		>* {
			width: 50%;

			@include mq {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 29px;
				}
			}
		}
	}

	&__list {

		counter-reset: number;
		color: black;
		// gap: 56px 0;
		line-height: 2;

		&-item {

			padding-left: 37px;

			@include mq {
				padding-left: 25px;
			}

			&:not(:last-child) {
				margin-bottom: 53px;

				@include mq {
					margin-bottom: 23px;
				}
			}

			// @include mq(md-min){
			// 	&:nth-child(2){
			// 		order: 3;
			// 	}
			// 	&:nth-child(3){
			// 		order: 5;
			// 	}
			// 	&:nth-child(4){
			// 		order: 7;
			// 	}
			// 	&:nth-child(5){
			// 		order: 2;
			// 	}
			// 	&:nth-child(6){
			// 		order: 4;
			// 	}
			// 	&:nth-child(7){
			// 		order: 6;
			// 	}
			// 	&:nth-child(8){
			// 		order: 8;
			// 	}
			// }
		}

		&-ttl {
			position: relative;
			margin-bottom: 16px;

			@include mq {
				margin-bottom: 7px;
			}

			span {
				position: absolute;
				left: -30px;
				top: 0;

				@include mq {
					left: -19px;
				}
			}

			// &::before{
			// 	counter-increment: number;
			// 	content: counter(number)".";
			// 	position: absolute;
			// 	left: -30px;
			// 	top: 0;
			// }
		}

		&-ct {
			.c-btn__03 {
				&:not(:first-child) {
					margin-top: 23px;

					@include mq {
						margin-top: 13px;
					}
				}
			}
		}

		&-flex {
			@include flex(0, end, wrap);
			gap: 5px 15px;
			margin-top: 30px;

			@include mq(sm) {
				max-width: 260px;
				margin: 0 auto;
			}

			.donation-top__list-img {
				width: 260px;
			}
		}

		&-txt {
			@include mq(sm) {
				width: 100%;
				// text-align: center;
			}
		}
	}
}

.donation-pay {
	background: #EEF3F8;
	padding: 151px 0 156px;

	@include mq {
		padding: 52px 0 54px;
	}

	&__ttl {
		margin-bottom: 52px;

		@include mq {
			margin-bottom: 25px;
		}
	}
}

.donation-list {
	counter-reset: number;
	line-height: 2;
	color: black;

	&__item {
		padding: 0 36px;

		@include mq {
			padding: 0;
			margin-bottom: 6px;
		}

		&:not(:last-child) {
			margin-bottom: 61px;
		}
	}

	&__ttl {
		position: relative;
		margin-bottom: 18px;

		@include mq {
			padding-left: 24px;
			margin-bottom: 6px;
		}

		&::before {
			counter-increment: number;
			content: counter(number)".";
			position: absolute;
			top: 0;
			left: -29px;
			position: absolute;

			@include mq {
				left: 0;
			}
		}
	}

	&__ct {
		letter-spacing: 1.7px;

		@include mq {
			letter-spacing: 0.7px;
		}

		p {
			&.number {
				position: relative;
				padding-left: 54px;
				margin-bottom: 29px;

				// color: #003D7D;
				a {
					text-decoration: underline;
					// font-weight: 700;
				}

				@include mq {
					padding-left: 65px;
					margin-bottom: 25px;
				}

				span {
					position: absolute;
					top: 0;
					left: 0;

					@include mq {
						left: 23px;
					}
				}
			}
		}

		&-brief {
			margin-bottom: 33px;

			@include mq {
				padding-left: 24px;
			}
		}
	}

	&__desc {
		@include fts(18, 36);
		margin-bottom: 51px;

		@include mq {
			@include fts(16, 28);
			margin-bottom: 30px;
		}

		&-item {
			@include flex(between, 0, wrap);

			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-ttl {
			width: 182px;
			@include flex(center, center);
			background: #003D7D;
			color: white;

			letter-spacing: 1px;

			@include mq {
				width: 143px;
			}
		}

		&-ct {
			width: calc(100% - 186px);
			background: white;
			padding: 27px 53px;
			color: #003D7D;

			@include mq {
				width: calc(100% - 147px);
				padding: 11px 29px 11px 32px;
			}
		}
	}

	&__info {
		border: 1px solid #003D7D;

		&:not(:last-child) {
			margin-bottom: 40px;

			@include mq {
				margin-bottom: 32px;
			}
		}

		&-ttl {
			background: #003D7D;
			@include fts(18, 36);
			font-weight: 400;
			text-align: center;
			padding: 4px 10px;
			color: white;
		}

		&-ct {
			padding: 28px 60px 38px;
			background: white;

			@include mq {
				padding: 21px 20px 21px;
			}
		}

		&-flex {
			@include flex(0, center, wrap);
			margin-left: -42px;
			margin-top: 10px;
			gap: 37px 64px;

			@include mq {
				margin-left: 0;
			}

			.donation-list__info-img {
				width: 69%;
				position: relative;
				margin-bottom: 0;

				@include mq {
					width: 100%;
				}

				@include p-before(28px, 24px) {
					background: #003D7D;
					top: 50%;
					left: 100%;
					transform: translateY(-50%);
					clip-path: polygon(0 0, 0% 100%, 100% 50%);

					@include mq {
						width: 18px;
						height: 16px;
						left: 50%;
						top: 100%;
						transform: translateY(-50%);
						clip-path: polygon(0 0, 50% 100%, 100% 0);
					}
				}
			}

			p {
				@include fts(18, 36);
				font-weight: 700;
				color: #003D7D;
				width: 177px;

				@include mq {
					@include fts(14, 28);
					text-align: center;
				}
			}
		}

		&-img {
			margin-bottom: 61px;
			text-align: center;

			@include mq {
				margin-bottom: 39px;
				margin-left: -20px;
			}
		}
	}

	&__note {
		li {
			position: relative;
			padding-left: 35px;

			&:not(:last-child) {
				margin-bottom: 31px;
			}

			@include p-before(5px, 5px) {
				background: currentColor;
				border-radius: 50%;
				left: 22px;
				top: 16px;
			}
		}
	}

	&__number {
		counter-reset: number;

		>li {

			padding-left: 35px;

			@include mq {
				padding-left: 20px;
			}

			&:not(:last-child) {
				margin-bottom: 31px;

				@include mq {
					margin-bottom: 12px;
				}
			}
		}

		&-ttl {
			position: relative;
			color: #003D7D;

			a {
				font-weight: 700;
				text-decoration: underline;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}

			&::before {
				counter-increment: number;
				content: counter(number)".";
				position: absolute;
				left: -18px;
				top: 0;
			}
		}

	}
}

.donation-deduct {
	padding: 122px 0 160px;

	@include mq {
		padding: 52px 0 50px;
	}

	&__ttl {
		margin-bottom: 52px;

		@include mq {
			margin-bottom: 23px;

		}
	}

	&__box {
		max-width: 886px;
		margin: 0 auto;

		.c-ttl__04 {
			margin-bottom: 36px;

			@include mq {
				margin-bottom: 17px;
			}
		}
	}

	&__list {
		@include flex(center, 0, wrap);
		gap: 82px;
		margin-bottom: 55px;

		@include mq(lg) {
			gap: 32px;
			margin-bottom: 26px;
		}

		&-item {
			width: 240px;

			@include mq(lg) {
				width: 95px;
			}

			@include mq(xs) {
				width: 70px;
			}

			&:nth-child(1) {
				.donation-deduct__list-ttl {
					@include p-after(32px, 16px) {
						border-bottom: 2px solid #003D7D;
						border-top: 2px solid #003D7D;
						left: calc(100% + 26px);
						top: 50%;
						transform: translateY(-50%);

						@include mq(lg) {
							width: 12px;
							border-width: 1px;
							height: 6px;
							left: calc(100% + 10px);
						}
					}
				}

			}

			&:nth-child(2) {
				.donation-deduct__list-ttl {
					@include p-after(32px, auto) {
						border-bottom: 2px solid #003D7D;
						left: calc(100% + 26px);
						top: 50%;
						transform: translateY(-50%);

						@include mq(lg) {
							width: 12px;
							border-width: 1px;
							height: 6px;
							left: calc(100% + 10px);
						}
					}
				}

			}
		}

		&-ttl {
			width: 240px;
			height: 240px;
			background: #EEF3F8;
			border-radius: 50%;
			@include fts(32, 47);
			font-family: $font-Sawarabi;
			font-weight: 400;
			text-align: center;
			@include flex(center, center);
			color: #003D7D;
			position: relative;

			@include mq(lg) {
				width: 95px;
				height: 95px;
				@include fts(14, 20);

			}

			@include mq(xs) {
				width: 70px;
				height: 70px;
			}

			&:not(:last-child) {
				margin-bottom: 23px;

				@include mq {
					margin-bottom: 10px;
				}
			}
		}

		p {
			color: #000000;
			text-align: center;

			@include mq(lg) {
				@include fts(10, 17.5);
			}
		}
	}

	&__desc {
		line-height: 2;
		margin-bottom: 28px;

		@include mq {
			@include fts(14, 28);
			margin-bottom: 22px;
		}

		.c-ttl__04 {
			margin-bottom: 15px;
		}

		p {
			color: #000;

			span {
				&:not(.stars) {
					color: #003D7D;
				}

				&.stars {
					@include font-size(10);
					display: inline-block;
					transform: translateY(-6px);

					@include mq {
						transform: translateY(-5px);
					}
				}
			}

			&:not(:last-child) {
				margin-bottom: 33px;

				@include mq {
					margin-bottom: 29px;
				}
			}
		}

		&-note {
			color: #777777;
		}
	}

	&__info {
		border: 1px solid #003D7D;

		&-ttl {
			background: #003D7D;
			@include fts(18, 36);
			font-weight: 400;
			text-align: center;
			padding: 4px 10px;
			color: white;

			@include mq {
				@include fts(16, 24);
				padding: 8px 10px 11px;
			}
		}

		&-ct {
			padding: 21px 60px 50px;
			background: white;
			line-height: 2;

			@include mq {
				padding: 11px 20px 31px;
			}
		}

		&-brief {
			margin-bottom: 40px;

			@include mq {
				@include fts(10, 17);
				margin-bottom: 17px;
			}
		}

		&-list {
			max-width: 410px;
			margin: 0 auto;

			dl {
				&:not(:last-child) {
					margin-bottom: 41px;

					@include mq {
						margin-bottom: 28px;
					}
				}

				dt {
					// margin-bottom: 6px;
				}

				dd {
					color: black;

					p {
						text-indent: -17px;
						padding-left: 18px;

						@include mq(sm) {
							text-indent: 0;
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

.donation-requirements {
	padding: 151px 0;
	background: #EEF3F8;
	margin-bottom: 159px;

	@include mq {
		padding: 54px 0 60px;
		// margin-bottom: 62px;
		margin-bottom: 0;
	}

	&__ttl {
		margin-bottom: 53px;

		@include mq {
			margin-bottom: 23px;
		}
	}

	&__list {
		max-width: 886px;
		margin: 0 auto;
		color: black;
		line-height: 2;
		letter-spacing: 1.5px;

		&-item {
			&:not(:last-child) {
				margin-bottom: 35px;

				@include mq {
					margin-bottom: 28px;
				}
			}
		}

		&-ttl {
			margin-bottom: 14px;
		}

		&-flex {
			@include flex(0, 0, wrap);
			gap: 25px 7.8%;
		}

		&-ct {
			width: 498px;
		}

		&-img {
			@include flex(0, end, wrap);
			gap: 13px;

			@include mq {
				gap: 22px;
				width: 100%;
				justify-content: center;
			}
		}

		&-txt {
			@include fts(14, 24.5);
		}
	}
}

.main-footer__page--top0 {
	padding-top: 0;
}
