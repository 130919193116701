@use "vars" as *;

/* ====================================================
Utility
==================================================== */
.pc {
	@include mq() {
		display: none
	}
	&-xxl{
		@include mq(xxl){
			display: none;
		}
	}
}
.sp {
	@include mq(md-min){
		display: none
	}
	&-xxl{
		@include mq(xxl-min){
			display: none;
		}
	}
}
.pc-sm {
	@include mq(sm){
		display: none
	}
}
.sp-sm {
	@include mq(sm-min){
		display: none
	}
}

// Hover style


