@use "vars" as *;

#page-rebuilding-news {
	padding-top: 108px;

	@include mq {
		padding-top: 73px;
	}
}


.sec-news {
	padding: 80px 0 273px;

	@include mq {
		padding: 60px 0 119px;
	}

	@include mq(sm) {
		background: #EEF3F8;
	}
}



.news-column {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;
	margin-bottom: 150px;

	@include mq {
		margin-bottom: 54px;
	}

	@include mq(sm) {
		grid-template-columns: 1fr;
		gap: 19px;
	}

	&__item {
		@include mq(sm) {
			border-bottom: 1px solid white;
		}
	}

	&__wrap {
		display: block;
		background: #EEF3F8;
		height: 100%;
	}

	&__img {
		@include mq(sm) {
			display: none;
		}
	}

	&__ct {

		@include mq(sm-min) {
			padding: 30px 28px 19px;

			@include mq {
				padding: 20px 10px;
			}
		}


		@include mq(sm) {
			@include flex(0, 0, wrap);
		}
	}

	&__ttl {
		@include fts(18, 31.5);
		font-weight: 500;
		color: #081B2B;
		margin-bottom: 10px;

		@include mq {
			@include fts(14, 28);
		}

		@include mq(sm) {
			order: 2;
			width: 100%;
		}
	}

	&__meta {
		@include flex(0, center, wrap);

		gap: 10px 39px;

		@include mq(sm-min) {
			padding-bottom: 14px;
			margin-bottom: 21px;
			border-bottom: 0.5px solid #777777;
		}

		@include mq {
			gap: 20px;
		}

		@include mq(sm) {
			order: 1;
			margin-bottom: 11px;
		}

		&-date {
			color: #777777;
			@include fts(14, 22.27);
			letter-spacing: 1.4px;

			@include mq(sm) {
				@include font-size(16);
			}
		}

		&-tag {
			@include flex(0, 0, wrap);

			span {
				min-width: 70px;
				min-height: 24px;
				border: 0.5px solid #003D7D;
				color: #003D7D;
				border-radius: 35px;
				@include fts(12, 17);
				@include flex(center, center);
				padding: 2px 12px;
			}
		}
	}

	&__btn {
		@include fts(16, 23);
		color: #003D7D;
		position: relative;
		padding-right: 45px;

		@include mq(sm) {
			display: none;
		}

		@include p-after(40px, 6px) {
			background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.nums-page {
	.wp-pagenavi {
		@include flex(center, center, wrap);
		gap: 10px 61px;

		@include mq {
			gap: 10px 18px;
		}

		>* {
			color: rgba($color: #003D7D, $alpha: .5);
			@include fts(24, 34);
			min-width: 24px;

			@include mq {
				@include font-size(14);
			}

			&.current {
				color: #003D7D;
			}

			&.dot {}

			&:hover {
				color: #003D7D;
			}

			&.previouspostslink {
				position: relative;
				min-height: 24px;
				color: #003D7D;

				@include p-after(15px, 15px) {
					border-top: 1px solid currentColor;
					border-left: 1px solid currentColor;
					transform: rotate(-45deg) translateY(-50%);
					right: 0;
					top: 50%;

					@include mq {
						width: 10px;
						height: 10px;
					}
				}
			}

			&.nextpostslink {
				position: relative;
				min-height: 24px;
				color: #003D7D;

				@include p-after(15px, 15px) {
					border-top: 1px solid currentColor;
					border-right: 1px solid currentColor;
					transform: rotate(45deg) translateY(-50%);
					right: 0;
					top: 50%;

					@include mq {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
}
// detail
#page-news_detail{
	background: #EEF3F8;
	padding-top: 184px;
	@include mq{
	    padding-top: 73px;
	}
	.mv-news {
		@include mq(md-min){
			display: none;
		}
	}
}
.sec-news-detail{
	padding-bottom: 281px;
	@include mq{
		padding-top: 20px;
		padding-bottom: 125px;
	}
	.inner{
		max-width: 928px;
	}
	&__btn{
		display: inline-block;
		position: relative;
		padding-right: 80px;
		@include fts(16, 23);
		color: #003D7D;
		@include p-after(40px, 6px) {
			background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.news-detail{
	background: white;
	padding: 48px 80px 59px;
	color: #081B2B;
	margin-bottom: 60px;
	@include mq{
		padding: 31px 20px 39px;
		margin-bottom: 40px;
	}
	&__ttl{
		@include fts(36, 54);
		font-weight: 500;
		color: #081B2B;
		margin-bottom: 18px;
		@include mq{
			@include fts(16, 28);
			margin-bottom: 4px;
		}
	}
	&__meta {
		@include flex(0, center, wrap);
		gap: 10px 39px;
	    margin-bottom: 17px;
		
		@include mq {
			gap: 20px;
			margin-bottom: 14px;
		}

		&-date {
			color: #777777;
			@include fts(14, 22.27);
			letter-spacing: 1.4px;

			@include mq(sm) {
				// @include font-size(16);
			}
		}

		&-tag {
			@include flex(0, 0, wrap);

			span {
				min-width: 70px;
				min-height: 24px;
				border: 0.5px solid #003D7D;
				color: #003D7D;
				border-radius: 35px;
				@include fts(12, 17);
				@include flex(center, center);
				padding: 2px 12px;
			}
		}
	}
	&__img{
	    margin-bottom: 21px;
		@include mq{
		    margin-bottom: 10px;
		}
	}
	&__ct{
		@include fts(16, 32);
		@include mq{
			@include font-size(14);
		}
	}
}
