@use "vars" as *;


.sec-spirit{
	// padding-top: 40px;
	&__breif{
		margin-bottom: 54px;
		color: black;
		letter-spacing: 1px;
		@include mq{
			@include fts(14, 28);
			margin-bottom: 31px;
		}
		&.c-txt__01{
		
		}
	
	}
}
.spirit-top{
	padding: 40px 0 160px;
	@include mq{
		padding: 58px 0 60px;
	}
}
.spirit-info{
	border: 4px solid #EEF3F8;
	border-radius: 10px;
	padding: 40px 56px 91px;
	color: black;
	@include mq{
		padding: 27px 36px;
	}
	&:not(:last-child){
		margin-bottom: 42px;
		@include mq{
			margin-bottom: 21px;
		}
	}
	&__ttl{
	    margin-bottom: 23px;
		@include mq{
			text-align: center;
		}
	}
	&__list{
		max-width: 660px;
		margin: auto;
		color: black;
		line-height: 3;
		@include mq{
			@include fts(14, 28);
			letter-spacing: 1px;
		}
		li{
			position: relative;
			padding-left: 47px;
			@include mq{
				padding-left: 30px;
			}
			span{
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}
.spirit-maxim{
	@include flex(0,0, wrap);
	padding-top: 26px;
	gap: 40px 0;
	max-width: 960px;
	margin: 0 auto;
	@include mq{
		padding-top: 0;
		gap: 26px 0;
	}
	&__item{
		width: 50%;
		@include mq(sm){
			width: 100%;
			padding-bottom: 39px;
			border-bottom: 4px solid #EEF3F8;
		}
		&:first-child{
			width: 100%;
			
			@include mq(sm-min){
				border-bottom: 4px solid #EEF3F8;
				padding-bottom: 60px;
			}
		}
		&:not(:first-child){
			@include mq(sm-min){
				padding: 0 10px;
				
			}
			@include mq(md-min){
				.spirit-maxim{
					&__list{
						gap: 0 9px;
					}
				}
			}
			
		}
		&:nth-child(2n){
			@include mq(sm-min){
				border-right: 4px solid #EEF3F8;
			}
		
		}
		&:last-child{
			border-right: 0;
		}
	}
	&__ttl{
		@include fts(21,42);
		font-weight: 400;
		text-align: center;
		color: black;
		margin-bottom: 48px;
		@include mq{
			@include font-size(14);
			margin-bottom: 12px;
		}
	}
	&__list{
		@include flex(center, 0, wrap);
		gap: 9px;
		@include mq{
			gap: 5.7px;
		}
		&-item{
			width: 152px;
			height: 152px;
			border-radius: 50%;
			@include flex(center, center);
			background: #EEF3F8;
			@include fts(18, 27);
			color: #003D7D;
			text-align: center;
			
			@include mq{
				width: 86px;
				height: 86px;
				@include font-size(12);
			}
		}
	}
}
.spirit-concept{
	background: #EEF3F8;
	padding: 161px 0 280px;
	@include mq{
		padding: 53px 0 117px;
	}
	&__ttl{
		margin-bottom: 41px;
		@include mq{
		    margin-bottom: 26px;
		}
	}
	&__brief{
		line-height: 2;
		letter-spacing: 1.6px;
		@include mq{
			letter-spacing: 1px;
		}
	}
}
