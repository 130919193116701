@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "DIN Condensed";
  src: url(./common/font/DINCondensed-Bold.ttf) format("TrueType");
  font-weight: 700;
}
/* ====================================================
HTML
==================================================== */
@media screen and (min-width: 835px) {
  body.is-page-rebuilding {
    overflow-x: unset;
  }
}

/* ====================================================
Layout
==================================================== */
.mv-news {
  background: #003D7D;
  color: white;
  padding: 32px 0;
}
@media screen and (max-width: 834px) {
  .mv-news {
    padding: 12px 0;
  }
}
.mv-news__ttl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 60px;
  font-size: 6rem;
  line-height: 1;
}
@media screen and (max-width: 1200px) {
  .mv-news__ttl {
    font-size: 4rem;
  }
}
@media screen and (max-width: 834px) {
  .mv-news__ttl {
    font-size: 1.6rem;
  }
}
.mv-news__ttl span {
  font-family: "A-OTF A1 Mincho Std", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
.mv-news__ttl small {
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .mv-news__ttl small {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 834px) {
  .main-footer__copyright {
    background: transparent;
  }
}

@media screen and (min-width: 835px) {
  .news-tab {
    min-height: 707px;
  }
}
.news-tab__ttl {
  text-align: center;
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .news-tab__ttl {
    margin-bottom: 22px;
  }
}
.news-tab__box {
  margin-bottom: 34px;
  padding-bottom: 10px;
  border-bottom: 1px solid #003D7D;
  position: relative;
}
@media screen and (max-width: 640px) {
  .news-tab__box {
    margin-bottom: 2px;
    border-bottom: 0;
  }
}
.news-tab .c-btn__01 {
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .news-tab .c-btn__01 {
    background: transparent;
    border-color: transparent;
    max-width: 196px;
  }
}
.news-tab__choose {
  min-height: 52px;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #003D7D;
  color: white;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4375;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
}
.news-tab__choose:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  right: 23px;
  top: 43%;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  transform: translateY(-50%) rotate(45deg);
}
@media screen and (min-width: 641px) {
  .news-tab__choose {
    display: none;
  }
}
@media screen and (min-width: 641px) {
  .news-tab__link {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
  }
}
@media screen and (max-width: 640px) {
  .news-tab__link {
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    width: 100%;
    z-index: 10;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in;
  }
  .news-tab__link.is-show {
    height: auto;
    border-top: 1px solid white;
  }
}
@media screen and (max-width: 640px) {
  .news-tab__link-item {
    border-bottom: 1px solid white;
  }
}
@media screen and (min-width: 641px) {
  .news-tab__link-item {
    width: 25%;
  }
  .news-tab__link-item:not(:last-child) {
    border-right: 1px solid #003D7D;
  }
}
.news-tab__link-item.active a {
  background: #003D7D;
  color: white;
  min-height: 60px;
}
@media screen and (max-width: 640px) {
  .news-tab__link-item.active {
    display: none;
  }
}
.news-tab__link-item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  color: #003D7D;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4166666667;
  font-size: 2.1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
@media screen and (min-width: 835px) {
  .news-tab__link-item a:hover {
    background: #003D7D;
    color: white;
    min-height: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .news-tab__link-item a {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .news-tab__link-item a {
    color: white;
    background: #003D7D;
    padding: 10px 20px;
    justify-content: start;
  }
}
.news-tab__content:not(.active) {
  display: none;
}
.news-tab__content-notitem {
  min-height: 350px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-list {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 39px;
}
@media screen and (max-width: 834px) {
  .news-list {
    margin-bottom: 7px;
  }
}
.news-list__item {
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 37px;
}
@media screen and (max-width: 834px) {
  .news-list__item {
    padding-bottom: 11px;
  }
}
.news-list__item:not(:last-child) {
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .news-list__item:not(:last-child) {
    margin-bottom: 16px;
  }
}
.news-list__item a {
  transition: all 0.3s ease-in;
}
.news-list__item a:hover {
  opacity: 0.7;
}
.news-list__item a:hover::after {
  right: 0;
}
.news-list__wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (min-width: 835px) {
  .news-list__wrap {
    padding-right: 35px;
  }
}
.news-list__wrap:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 28px;
  height: 4px;
  background: url(./common/img/icon-arrow-right-blue.png) no-repeat center/contain;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in;
}
@media screen and (max-width: 834px) {
  .news-list__wrap:after {
    display: none;
  }
}
.news-list__meta {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 110px;
  gap: 10px 40px;
}
@media screen and (max-width: 834px) {
  .news-list__meta {
    width: 100%;
    gap: 10px 21px;
    margin-bottom: 8px;
  }
}
.news-list__meta-date {
  color: #777777;
  letter-spacing: 1px;
}
.news-list__meta-tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4166666667;
}
.news-list__meta-tag span {
  border: 1px solid #003D7D;
  min-width: 70px;
  min-height: 24px;
  color: #003D7D;
  border-radius: 35px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.news-list__ttl {
  width: calc(100% - 110px);
  font-weight: 400;
  letter-spacing: 3.3px;
}
@media screen and (max-width: 834px) {
  .news-list__ttl {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
  }
}

/* ====================================================
Control
==================================================== */
.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-val--md {
  vertical-align: middle;
}
.c-val--top {
  vertical-align: top;
}

/* ====================================================
Component
==================================================== */
.c-ttl__04 {
  font-family: "Sawarabi Gothic", sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 2;
  font-weight: 400;
  color: #003D7D;
}
@media screen and (max-width: 834px) {
  .c-ttl__04 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

.c-btn__03 {
  position: relative;
  display: inline-block;
  padding-right: 60px;
}
.c-btn__03:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 6px;
  background: url(../img/rebuilding-donation/icon-arrow.png) no-repeat center/contain;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* ====================================================
Utility
==================================================== */
@media screen and (max-width: 834px) {
  .pc {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .pc-xxl {
    display: none;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none;
  }
}
@media screen and (min-width: 1501px) {
  .sp-xxl {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none;
  }
}

#page-rebuilding_index {
  padding-top: 0;
  position: relative;
  z-index: 11;
}

.mv-top__wrapper {
  max-width: 100%;
  height: auto;
}
.mv-top__wrapper #card {
  overflow: hidden;
}
.mv-top__slide-item img {
  width: 100%;
  transition: 3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.mv-top__slide-item.slick-active img {
  transform: scale(1.1, 1.1);
}
.mv-top__ttl {
  top: auto;
  bottom: 75px;
  left: 124px;
}
@media screen and (max-width: 834px) {
  .mv-top__ttl {
    margin-top: 62px;
  }
}

.top-news {
  padding: 10.7% 0 160px;
}
@media screen and (max-width: 834px) {
  .top-news {
    padding: 0 0 60px;
    margin: 63px 0 60px;
    background: #EEF3F8;
  }
}
.top-news .news-tab {
  background: #EEF3F8;
  padding: 52px 80px 60px;
}
@media screen and (max-width: 1200px) {
  .top-news .news-tab {
    padding: 40px;
  }
}
@media screen and (max-width: 834px) {
  .top-news .news-tab {
    padding: 50px 20px 40px;
    margin: 0 -5vw;
  }
}

.top-video video {
  width: 100%;
}

.news-categories__item {
  border-top: 1px solid #003D7D;
}
.news-categories__item:last-child {
  border-bottom: 1px solid #003D7D;
}
.news-categories__item a {
  display: block;
  color: #003D7D;
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 600;
  padding: 13px 35px 14px 20px;
}
.news-categories__item a:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
  top: 50%;
  right: 26px;
  transform: translateY(-50%) rotate(45deg);
}

@media screen and (min-width: 835px) {
  .trajectory-top {
    margin-bottom: 0;
    padding-bottom: 10%;
    padding-top: 55%;
    z-index: 4;
  }
}
@media screen and (max-width: 834px) {
  .trajectory-top {
    padding-bottom: 42px;
  }
}
@media screen and (min-width: 835px) {
  .trajectory-top__list {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    max-width: 100%;
  }
}
@media screen and (min-width: 835px) {
  .trajectory-top__list:not(.trajectory-top__list--styles02) li {
    position: absolute;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(1) {
    width: 13.6%;
    top: -11.6vw;
    left: 8.9%;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(2) {
    width: 15.6%;
    left: 21.3%;
    top: 0.8%;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(3) {
    width: 18.9%;
    left: 51.2%;
    top: -8.5vw;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(4) {
    width: 10.6%;
    right: 15.6%;
    top: 12%;
    z-index: 2;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(5) {
    width: 15.2%;
    right: 2.6%;
    top: -9.8vw;
  }
  .trajectory-top__list:not(.trajectory-top__list--styles02) li:nth-child(6) {
    width: 22.2%;
    left: 4.5%;
    top: 30.2%;
  }
}
@media screen and (min-width: 835px) {
  .trajectory-top__list--styles02 li {
    position: absolute;
  }
  .trajectory-top__list--styles02 li:nth-child(1) {
    width: 17.6%;
    bottom: -6vw;
    left: 0;
  }
  .trajectory-top__list--styles02 li:nth-child(2) {
    width: 14.9%;
    left: 12.3%;
    bottom: -14.8vw;
  }
  .trajectory-top__list--styles02 li:nth-child(3) {
    width: 15.2%;
    left: 38.1%;
    bottom: -30.9vw;
  }
  .trajectory-top__list--styles02 li:nth-child(4) {
    width: 12.8%;
    right: 24.2%;
    bottom: -12vw;
    z-index: 2;
  }
  .trajectory-top__list--styles02 li:nth-child(5) {
    width: 21.2%;
    right: 2.6%;
    bottom: 15%;
  }
  .trajectory-top__list--styles02 li:nth-child(6) {
    width: 20.2%;
    right: 6%;
    bottom: -24.4vw;
  }
}

.plan-top {
  display: block;
}
@media screen and (max-width: 834px) {
  .plan-top {
    margin-bottom: 79px;
  }
}
.plan-top__ct {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .plan-top__ct {
    padding: 46px 20px 52px;
  }
}
@media screen and (max-width: 834px) {
  .plan-top__img {
    margin-bottom: 55px;
  }
}
@media screen and (max-width: 834px) {
  .plan-top__img::before {
    padding-top: 53.25%;
  }
}
@media screen and (min-width: 835px) {
  .plan-top {
    margin-bottom: 0;
    padding-bottom: 35px;
    padding-top: 46%;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
  .plan-top__box {
    margin: 0 auto -21vw;
  }
  .plan-top__ct {
    text-align: center;
    margin: 0 auto 76px;
    width: 100%;
    padding: 0;
  }
  .plan-top__ct .c-txt__01,
  .plan-top__ct .c-ttl__01 {
    color: white;
  }
  .plan-top__ct-ttl {
    margin-bottom: 34px;
  }
  .plan-top__img {
    max-width: 505px;
    width: 100%;
    margin: 0 auto 7px;
  }
  .plan-top__img img {
    height: 86%;
  }
  .plan-top .c-btn__01 {
    margin: 0 auto;
    background: white;
    color: #003D7D;
    border-color: white;
    width: 100%;
    text-align: left;
  }
  .plan-top .c-btn__01:after {
    background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
  }
}
.plan-top__ct-desc {
  margin-bottom: 0;
}
.plan-top .c-btn__01 {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 835px) {
  .c-btn__01--whitesp {
    margin: 0 auto;
    background: white;
    color: #003D7D;
    border-color: white;
    width: 100%;
    text-align: left;
  }
  .c-btn__01--whitesp:after {
    background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
  }
}
@media screen and (max-width: 834px) {
  .c-btn__01--whitesp {
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.rebuilding-brn {
  position: fixed;
  bottom: 49px;
  right: 24px;
  z-index: 10;
}

@media screen and (min-width: 835px) {
  .about-top {
    clip-path: none;
    padding-top: 33vw;
    padding-bottom: 13.5vw;
    background: transparent;
  }
  .about-top__ttl {
    margin-bottom: 38px;
  }
  .about-top__desc {
    color: white;
  }
}
@media screen and (max-width: 834px) {
  .about-top {
    padding: 64px 0 64px;
  }
}
.about-top .c-btn__01 {
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .building-top {
    padding: 110px 0 102px;
  }
}
.building-top__block {
  text-align: center;
  width: 100%;
}
.building-top__block .c-btn__01 {
  margin: 0 auto;
}

.sec-video {
  position: relative;
  z-index: 10;
}
.sec-video__video {
  position: sticky;
  z-index: -1;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: all 1s;
  transform-origin: bottom;
}
@media screen and (max-width: 640px) {
  .sec-video__video {
    display: none;
  }
}
.sec-video__video video {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  object-fit: cover;
  transition: all 1s;
  transform-origin: center;
}
@media screen and (min-width: 835px) {
  .sec-video .c-ttl__01--ff, .sec-video .c-ttl__01 {
    color: white;
  }
}

#main-footer.sec-video {
  position: relative;
}
#page-rebuilding-news {
  padding-top: 108px;
}
@media screen and (max-width: 834px) {
  #page-rebuilding-news {
    padding-top: 73px;
  }
}

.sec-news {
  padding: 80px 0 273px;
}
@media screen and (max-width: 834px) {
  .sec-news {
    padding: 60px 0 119px;
  }
}
@media screen and (max-width: 640px) {
  .sec-news {
    background: #EEF3F8;
  }
}

.news-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 150px;
}
@media screen and (max-width: 834px) {
  .news-column {
    margin-bottom: 54px;
  }
}
@media screen and (max-width: 640px) {
  .news-column {
    grid-template-columns: 1fr;
    gap: 19px;
  }
}
@media screen and (max-width: 640px) {
  .news-column__item {
    border-bottom: 1px solid white;
  }
}
.news-column__wrap {
  display: block;
  background: #EEF3F8;
  height: 100%;
}
@media screen and (max-width: 640px) {
  .news-column__img {
    display: none;
  }
}
@media screen and (min-width: 641px) {
  .news-column__ct {
    padding: 30px 28px 19px;
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .news-column__ct {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 640px) {
  .news-column__ct {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.news-column__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.75;
  font-weight: 500;
  color: #081B2B;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .news-column__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
  }
}
@media screen and (max-width: 640px) {
  .news-column__ttl {
    order: 2;
    width: 100%;
  }
}
.news-column__meta {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 39px;
}
@media screen and (min-width: 641px) {
  .news-column__meta {
    padding-bottom: 14px;
    margin-bottom: 21px;
    border-bottom: 0.5px solid #777777;
  }
}
@media screen and (max-width: 834px) {
  .news-column__meta {
    gap: 20px;
  }
}
@media screen and (max-width: 640px) {
  .news-column__meta {
    order: 1;
    margin-bottom: 11px;
  }
}
.news-column__meta-date {
  color: #777777;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5907142857;
  letter-spacing: 1.4px;
}
@media screen and (max-width: 640px) {
  .news-column__meta-date {
    font-size: 1.6rem;
  }
}
.news-column__meta-tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.news-column__meta-tag span {
  min-width: 70px;
  min-height: 24px;
  border: 0.5px solid #003D7D;
  color: #003D7D;
  border-radius: 35px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4166666667;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2px 12px;
}
.news-column__btn {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4375;
  color: #003D7D;
  position: relative;
  padding-right: 45px;
}
@media screen and (max-width: 640px) {
  .news-column__btn {
    display: none;
  }
}
.news-column__btn:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 6px;
  background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nums-page .wp-pagenavi {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 61px;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi {
    gap: 10px 18px;
  }
}
.nums-page .wp-pagenavi > * {
  color: rgba(0, 61, 125, 0.5);
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4166666667;
  min-width: 24px;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi > * {
    font-size: 1.4rem;
  }
}
.nums-page .wp-pagenavi > *.current {
  color: #003D7D;
}
.nums-page .wp-pagenavi > *:hover {
  color: #003D7D;
}
.nums-page .wp-pagenavi > *.previouspostslink {
  position: relative;
  min-height: 24px;
  color: #003D7D;
}
.nums-page .wp-pagenavi > *.previouspostslink:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 1px solid currentColor;
  border-left: 1px solid currentColor;
  transform: rotate(-45deg) translateY(-50%);
  right: 0;
  top: 50%;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi > *.previouspostslink:after {
    width: 10px;
    height: 10px;
  }
}
.nums-page .wp-pagenavi > *.nextpostslink {
  position: relative;
  min-height: 24px;
  color: #003D7D;
}
.nums-page .wp-pagenavi > *.nextpostslink:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
  transform: rotate(45deg) translateY(-50%);
  right: 0;
  top: 50%;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi > *.nextpostslink:after {
    width: 10px;
    height: 10px;
  }
}

#page-news_detail {
  background: #EEF3F8;
  padding-top: 184px;
}
@media screen and (max-width: 834px) {
  #page-news_detail {
    padding-top: 73px;
  }
}
@media screen and (min-width: 835px) {
  #page-news_detail .mv-news {
    display: none;
  }
}

.sec-news-detail {
  padding-bottom: 281px;
}
@media screen and (max-width: 834px) {
  .sec-news-detail {
    padding-top: 20px;
    padding-bottom: 125px;
  }
}
.sec-news-detail .inner {
  max-width: 928px;
}
.sec-news-detail__btn {
  display: inline-block;
  position: relative;
  padding-right: 80px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4375;
  color: #003D7D;
}
.sec-news-detail__btn:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 6px;
  background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.news-detail {
  background: white;
  padding: 48px 80px 59px;
  color: #081B2B;
  margin-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .news-detail {
    padding: 31px 20px 39px;
    margin-bottom: 40px;
  }
}
.news-detail__ttl {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.5;
  font-weight: 500;
  color: #081B2B;
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .news-detail__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
    margin-bottom: 4px;
  }
}
.news-detail__meta {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 39px;
  margin-bottom: 17px;
}
@media screen and (max-width: 834px) {
  .news-detail__meta {
    gap: 20px;
    margin-bottom: 14px;
  }
}
.news-detail__meta-date {
  color: #777777;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5907142857;
  letter-spacing: 1.4px;
}
.news-detail__meta-tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.news-detail__meta-tag span {
  min-width: 70px;
  min-height: 24px;
  border: 0.5px solid #003D7D;
  color: #003D7D;
  border-radius: 35px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4166666667;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2px 12px;
}
.news-detail__img {
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .news-detail__img {
    margin-bottom: 10px;
  }
}
.news-detail__ct {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .news-detail__ct {
    font-size: 1.4rem;
  }
}

.sec-donation {
  color: black;
}
.sec-donation .c-ttl__01 {
  font-family: "Sawarabi Gothic", sans-serif;
  color: black;
}
@media screen and (max-width: 834px) {
  .sec-donation .c-ttl__01 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5555555556;
  }
}
@media screen and (max-width: 834px) {
  .sec-donation__link {
    opacity: 1;
    color: #003D7D;
  }
}

.donation-top {
  padding: 54px 0 156px;
}
@media screen and (max-width: 834px) {
  .donation-top {
    padding: 0 0 58px;
  }
}
.donation-top .c-ttl__01 {
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .donation-top .c-ttl__01 {
    margin-bottom: 24px;
  }
}
.donation-top__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.donation-top__flex > * {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .donation-top__flex > * {
    width: 100%;
  }
  .donation-top__flex > *:not(:last-child) {
    margin-bottom: 29px;
  }
}
.donation-top__list {
  counter-reset: number;
  color: black;
  line-height: 2;
}
.donation-top__list-item {
  padding-left: 37px;
}
@media screen and (max-width: 834px) {
  .donation-top__list-item {
    padding-left: 25px;
  }
}
.donation-top__list-item:not(:last-child) {
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .donation-top__list-item:not(:last-child) {
    margin-bottom: 23px;
  }
}
.donation-top__list-ttl {
  position: relative;
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .donation-top__list-ttl {
    margin-bottom: 7px;
  }
}
.donation-top__list-ttl span {
  position: absolute;
  left: -30px;
  top: 0;
}
@media screen and (max-width: 834px) {
  .donation-top__list-ttl span {
    left: -19px;
  }
}
.donation-top__list-ct .c-btn__03:not(:first-child) {
  margin-top: 23px;
}
@media screen and (max-width: 834px) {
  .donation-top__list-ct .c-btn__03:not(:first-child) {
    margin-top: 13px;
  }
}
.donation-top__list-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px 15px;
  margin-top: 30px;
}
@media screen and (max-width: 640px) {
  .donation-top__list-flex {
    max-width: 260px;
    margin: 0 auto;
  }
}
.donation-top__list-flex .donation-top__list-img {
  width: 260px;
}
@media screen and (max-width: 640px) {
  .donation-top__list-txt {
    width: 100%;
  }
}

.donation-pay {
  background: #EEF3F8;
  padding: 151px 0 156px;
}
@media screen and (max-width: 834px) {
  .donation-pay {
    padding: 52px 0 54px;
  }
}
.donation-pay__ttl {
  margin-bottom: 52px;
}
@media screen and (max-width: 834px) {
  .donation-pay__ttl {
    margin-bottom: 25px;
  }
}

.donation-list {
  counter-reset: number;
  line-height: 2;
  color: black;
}
.donation-list__item {
  padding: 0 36px;
}
@media screen and (max-width: 834px) {
  .donation-list__item {
    padding: 0;
    margin-bottom: 6px;
  }
}
.donation-list__item:not(:last-child) {
  margin-bottom: 61px;
}
.donation-list__ttl {
  position: relative;
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .donation-list__ttl {
    padding-left: 24px;
    margin-bottom: 6px;
  }
}
.donation-list__ttl::before {
  counter-increment: number;
  content: counter(number) ".";
  position: absolute;
  top: 0;
  left: -29px;
  position: absolute;
}
@media screen and (max-width: 834px) {
  .donation-list__ttl::before {
    left: 0;
  }
}
.donation-list__ct {
  letter-spacing: 1.7px;
}
@media screen and (max-width: 834px) {
  .donation-list__ct {
    letter-spacing: 0.7px;
  }
}
.donation-list__ct p.number {
  position: relative;
  padding-left: 54px;
  margin-bottom: 29px;
}
.donation-list__ct p.number a {
  text-decoration: underline;
}
@media screen and (max-width: 834px) {
  .donation-list__ct p.number {
    padding-left: 65px;
    margin-bottom: 25px;
  }
}
.donation-list__ct p.number span {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .donation-list__ct p.number span {
    left: 23px;
  }
}
.donation-list__ct-brief {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .donation-list__ct-brief {
    padding-left: 24px;
  }
}
.donation-list__desc {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 51px;
}
@media screen and (max-width: 834px) {
  .donation-list__desc {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
    margin-bottom: 30px;
  }
}
.donation-list__desc-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.donation-list__desc-item:not(:last-child) {
  margin-bottom: 4px;
}
.donation-list__desc-ttl {
  width: 182px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #003D7D;
  color: white;
  letter-spacing: 1px;
}
@media screen and (max-width: 834px) {
  .donation-list__desc-ttl {
    width: 143px;
  }
}
.donation-list__desc-ct {
  width: calc(100% - 186px);
  background: white;
  padding: 27px 53px;
  color: #003D7D;
}
@media screen and (max-width: 834px) {
  .donation-list__desc-ct {
    width: calc(100% - 147px);
    padding: 11px 29px 11px 32px;
  }
}
.donation-list__info {
  border: 1px solid #003D7D;
}
.donation-list__info:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .donation-list__info:not(:last-child) {
    margin-bottom: 32px;
  }
}
.donation-list__info-ttl {
  background: #003D7D;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  font-weight: 400;
  text-align: center;
  padding: 4px 10px;
  color: white;
}
.donation-list__info-ct {
  padding: 28px 60px 38px;
  background: white;
}
@media screen and (max-width: 834px) {
  .donation-list__info-ct {
    padding: 21px 20px 21px;
  }
}
.donation-list__info-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -42px;
  margin-top: 10px;
  gap: 37px 64px;
}
@media screen and (max-width: 834px) {
  .donation-list__info-flex {
    margin-left: 0;
  }
}
.donation-list__info-flex .donation-list__info-img {
  width: 69%;
  position: relative;
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .donation-list__info-flex .donation-list__info-img {
    width: 100%;
  }
}
.donation-list__info-flex .donation-list__info-img:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 28px;
  height: 24px;
  background: #003D7D;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
@media screen and (max-width: 834px) {
  .donation-list__info-flex .donation-list__info-img:before {
    width: 18px;
    height: 16px;
    left: 50%;
    top: 100%;
    transform: translateY(-50%);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
  }
}
.donation-list__info-flex p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  font-weight: 700;
  color: #003D7D;
  width: 177px;
}
@media screen and (max-width: 834px) {
  .donation-list__info-flex p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    text-align: center;
  }
}
.donation-list__info-img {
  margin-bottom: 61px;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .donation-list__info-img {
    margin-bottom: 39px;
    margin-left: -20px;
  }
}
.donation-list__note li {
  position: relative;
  padding-left: 35px;
}
.donation-list__note li:not(:last-child) {
  margin-bottom: 31px;
}
.donation-list__note li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 5px;
  background: currentColor;
  border-radius: 50%;
  left: 22px;
  top: 16px;
}
.donation-list__number {
  counter-reset: number;
}
.donation-list__number > li {
  padding-left: 35px;
}
@media screen and (max-width: 834px) {
  .donation-list__number > li {
    padding-left: 20px;
  }
}
.donation-list__number > li:not(:last-child) {
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  .donation-list__number > li:not(:last-child) {
    margin-bottom: 12px;
  }
}
.donation-list__number-ttl {
  position: relative;
  color: #003D7D;
}
.donation-list__number-ttl a {
  font-weight: 700;
  text-decoration: underline;
}
.donation-list__number-ttl:not(:last-child) {
  margin-bottom: 20px;
}
.donation-list__number-ttl::before {
  counter-increment: number;
  content: counter(number) ".";
  position: absolute;
  left: -18px;
  top: 0;
}

.donation-deduct {
  padding: 122px 0 160px;
}
@media screen and (max-width: 834px) {
  .donation-deduct {
    padding: 52px 0 50px;
  }
}
.donation-deduct__ttl {
  margin-bottom: 52px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__ttl {
    margin-bottom: 23px;
  }
}
.donation-deduct__box {
  max-width: 886px;
  margin: 0 auto;
}
.donation-deduct__box .c-ttl__04 {
  margin-bottom: 36px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__box .c-ttl__04 {
    margin-bottom: 17px;
  }
}
.donation-deduct__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 82px;
  margin-bottom: 55px;
}
@media screen and (max-width: 990px) {
  .donation-deduct__list {
    gap: 32px;
    margin-bottom: 26px;
  }
}
.donation-deduct__list-item {
  width: 240px;
}
@media screen and (max-width: 990px) {
  .donation-deduct__list-item {
    width: 95px;
  }
}
@media screen and (max-width: 374px) {
  .donation-deduct__list-item {
    width: 70px;
  }
}
.donation-deduct__list-item:nth-child(1) .donation-deduct__list-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 16px;
  border-bottom: 2px solid #003D7D;
  border-top: 2px solid #003D7D;
  left: calc(100% + 26px);
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 990px) {
  .donation-deduct__list-item:nth-child(1) .donation-deduct__list-ttl:after {
    width: 12px;
    border-width: 1px;
    height: 6px;
    left: calc(100% + 10px);
  }
}
.donation-deduct__list-item:nth-child(2) .donation-deduct__list-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: auto;
  border-bottom: 2px solid #003D7D;
  left: calc(100% + 26px);
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 990px) {
  .donation-deduct__list-item:nth-child(2) .donation-deduct__list-ttl:after {
    width: 12px;
    border-width: 1px;
    height: 6px;
    left: calc(100% + 10px);
  }
}
.donation-deduct__list-ttl {
  width: 240px;
  height: 240px;
  background: #EEF3F8;
  border-radius: 50%;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.46875;
  font-family: "Sawarabi Gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #003D7D;
  position: relative;
}
@media screen and (max-width: 990px) {
  .donation-deduct__list-ttl {
    width: 95px;
    height: 95px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4285714286;
  }
}
@media screen and (max-width: 374px) {
  .donation-deduct__list-ttl {
    width: 70px;
    height: 70px;
  }
}
.donation-deduct__list-ttl:not(:last-child) {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__list-ttl:not(:last-child) {
    margin-bottom: 10px;
  }
}
.donation-deduct__list p {
  color: #000000;
  text-align: center;
}
@media screen and (max-width: 990px) {
  .donation-deduct__list p {
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.75;
  }
}
.donation-deduct__desc {
  line-height: 2;
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__desc {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 22px;
  }
}
.donation-deduct__desc .c-ttl__04 {
  margin-bottom: 15px;
}
.donation-deduct__desc p {
  color: #000;
}
.donation-deduct__desc p span:not(.stars) {
  color: #003D7D;
}
.donation-deduct__desc p span.stars {
  font-size: 1rem;
  display: inline-block;
  transform: translateY(-6px);
}
@media screen and (max-width: 834px) {
  .donation-deduct__desc p span.stars {
    transform: translateY(-5px);
  }
}
.donation-deduct__desc p:not(:last-child) {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__desc p:not(:last-child) {
    margin-bottom: 29px;
  }
}
.donation-deduct__desc-note {
  color: #777777;
}
.donation-deduct__info {
  border: 1px solid #003D7D;
}
.donation-deduct__info-ttl {
  background: #003D7D;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  font-weight: 400;
  text-align: center;
  padding: 4px 10px;
  color: white;
}
@media screen and (max-width: 834px) {
  .donation-deduct__info-ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 8px 10px 11px;
  }
}
.donation-deduct__info-ct {
  padding: 21px 60px 50px;
  background: white;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .donation-deduct__info-ct {
    padding: 11px 20px 31px;
  }
}
.donation-deduct__info-brief {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__info-brief {
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: 17px;
  }
}
.donation-deduct__info-list {
  max-width: 410px;
  margin: 0 auto;
}
.donation-deduct__info-list dl:not(:last-child) {
  margin-bottom: 41px;
}
@media screen and (max-width: 834px) {
  .donation-deduct__info-list dl:not(:last-child) {
    margin-bottom: 28px;
  }
}
.donation-deduct__info-list dl dd {
  color: black;
}
.donation-deduct__info-list dl dd p {
  text-indent: -17px;
  padding-left: 18px;
}
@media screen and (max-width: 640px) {
  .donation-deduct__info-list dl dd p {
    text-indent: 0;
    padding-left: 0;
  }
}

.donation-requirements {
  padding: 151px 0;
  background: #EEF3F8;
  margin-bottom: 159px;
}
@media screen and (max-width: 834px) {
  .donation-requirements {
    padding: 54px 0 60px;
    margin-bottom: 0;
  }
}
.donation-requirements__ttl {
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .donation-requirements__ttl {
    margin-bottom: 23px;
  }
}
.donation-requirements__list {
  max-width: 886px;
  margin: 0 auto;
  color: black;
  line-height: 2;
  letter-spacing: 1.5px;
}
.donation-requirements__list-item:not(:last-child) {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .donation-requirements__list-item:not(:last-child) {
    margin-bottom: 28px;
  }
}
.donation-requirements__list-ttl {
  margin-bottom: 14px;
}
.donation-requirements__list-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 25px 7.8%;
}
.donation-requirements__list-ct {
  width: 498px;
}
.donation-requirements__list-img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 13px;
}
@media screen and (max-width: 834px) {
  .donation-requirements__list-img {
    gap: 22px;
    width: 100%;
    justify-content: center;
  }
}
.donation-requirements__list-txt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.75;
}

.main-footer__page--top0 {
  padding-top: 0;
}

.sec-history {
  padding: 93px 0 240px;
}
@media screen and (max-width: 834px) {
  .sec-history {
    padding: 23px 0 167px;
  }
}
.sec-history__ttl {
  text-align: center;
  font-size: 60px;
  font-size: 6rem;
  line-height: 1.5;
  color: black;
  margin-bottom: 122px;
}
@media screen and (max-width: 834px) {
  .sec-history__ttl {
    font-size: 33.49px;
    font-size: 3.349rem;
    line-height: 1.5001492983;
    margin-bottom: 11px;
  }
}
@media screen and (max-width: 374px) {
  .sec-history__ttl {
    font-size: 2.6rem;
  }
}
.sec-history__ttl > span {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.sec-history__ttl-first .c-ft--en {
  font-size: 48px;
  font-size: 4.8rem;
  line-height: 1.2;
  color: #003d7d;
  width: 382px;
  letter-spacing: 1px;
  position: relative;
  text-align: right;
  font-family: "DIN Condensed", sans-serif;
  margin-top: 18px;
}
.sec-history__ttl-first .c-ft--en span {
  background: white;
  display: inline-block;
  position: relative;
  z-index: 2;
  padding-left: 10px;
}
@media screen and (max-width: 834px) {
  .sec-history__ttl-first .c-ft--en {
    width: 218px;
    font-size: 2.679rem;
    margin-top: 10px;
  }
}
@media screen and (max-width: 374px) {
  .sec-history__ttl-first .c-ft--en {
    width: 154px;
  }
}
.sec-history__ttl-first .c-ft--en:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 181px;
  height: 4px;
  background: #003D7D;
  left: 42px;
  top: 41%;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .sec-history__ttl-first .c-ft--en:before {
    width: 100px;
    height: 1px;
    left: 27px;
  }
}
@media screen and (max-width: 374px) {
  .sec-history__ttl-first .c-ft--en:before {
    width: 60px;
    left: 12px;
  }
}
@media screen and (max-width: 640px) {
  .sec-history__img {
    padding-left: 16px;
  }
}

.history-box {
  position: relative;
  padding-top: 8.3%;
  padding-bottom: 16%;
}
@media screen and (min-width: 641px) {
  .history-box {
    text-align: center;
    margin-bottom: 74px;
  }
}
@media screen and (max-width: 640px) {
  .history-box {
    padding-top: 21.3%;
  }
}
.history-box:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 61, 125, 0) 0%, #003D7D 10%, #003D7D 90%, rgba(0, 61, 125, 0) 100%);
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .history-box:after {
    left: 4.5%;
    width: 1px;
    transform: none;
  }
}

.history-icon {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .history-icon img {
    width: 94px;
  }
}

.outline-equipment__brief {
  line-height: 2;
  color: black;
  margin-bottom: 33px;
}
.outline-equipment .outline-article {
  margin-bottom: 103px;
}
.outline-equipment__desc {
  margin-bottom: 60px;
}
.outline-equipment__desc-ttl {
  margin-bottom: 26px;
}
.outline-equipment__desc .c-txt__01 {
  line-height: 2;
  color: black;
  letter-spacing: 1.5px;
}
@media screen and (max-width: 834px) {
  .outline-equipment__desc .c-txt__01 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
  }
}
.outline-equipment__info {
  background: #EEF3F8;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 48px 80px;
  gap: 10px 0;
}
@media screen and (max-width: 834px) {
  .outline-equipment__info {
    padding: 20px;
  }
}
.outline-equipment__info-ttl {
  width: 165px;
}
@media screen and (max-width: 834px) {
  .outline-equipment__info-ttl {
    width: 100%;
  }
}
.outline-equipment__info .c-txt__01 {
  line-height: 2;
  color: black;
  letter-spacing: 1.5px;
  width: calc(100% - 165px);
}
.outline-equipment__info .c-txt__01 a {
  text-decoration: underline;
}
@media screen and (max-width: 834px) {
  .outline-equipment__info .c-txt__01 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    width: 100%;
  }
}

@media screen and (min-width: 835px) {
  .concept-list__item {
    padding: 45px 0 70px;
  }
}

.sec-spirit__breif {
  margin-bottom: 54px;
  color: black;
  letter-spacing: 1px;
}
@media screen and (max-width: 834px) {
  .sec-spirit__breif {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 31px;
  }
}
.spirit-top {
  padding: 40px 0 160px;
}
@media screen and (max-width: 834px) {
  .spirit-top {
    padding: 58px 0 60px;
  }
}

.spirit-info {
  border: 4px solid #EEF3F8;
  border-radius: 10px;
  padding: 40px 56px 91px;
  color: black;
}
@media screen and (max-width: 834px) {
  .spirit-info {
    padding: 27px 36px;
  }
}
.spirit-info:not(:last-child) {
  margin-bottom: 42px;
}
@media screen and (max-width: 834px) {
  .spirit-info:not(:last-child) {
    margin-bottom: 21px;
  }
}
.spirit-info__ttl {
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .spirit-info__ttl {
    text-align: center;
  }
}
.spirit-info__list {
  max-width: 660px;
  margin: auto;
  color: black;
  line-height: 3;
}
@media screen and (max-width: 834px) {
  .spirit-info__list {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 1px;
  }
}
.spirit-info__list li {
  position: relative;
  padding-left: 47px;
}
@media screen and (max-width: 834px) {
  .spirit-info__list li {
    padding-left: 30px;
  }
}
.spirit-info__list li span {
  position: absolute;
  left: 0;
  top: 0;
}

.spirit-maxim {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 26px;
  gap: 40px 0;
  max-width: 960px;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .spirit-maxim {
    padding-top: 0;
    gap: 26px 0;
  }
}
.spirit-maxim__item {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .spirit-maxim__item {
    width: 100%;
    padding-bottom: 39px;
    border-bottom: 4px solid #EEF3F8;
  }
}
.spirit-maxim__item:first-child {
  width: 100%;
}
@media screen and (min-width: 641px) {
  .spirit-maxim__item:first-child {
    border-bottom: 4px solid #EEF3F8;
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 641px) {
  .spirit-maxim__item:not(:first-child) {
    padding: 0 10px;
  }
}
@media screen and (min-width: 835px) {
  .spirit-maxim__item:not(:first-child) .spirit-maxim__list {
    gap: 0 9px;
  }
}
@media screen and (min-width: 641px) {
  .spirit-maxim__item:nth-child(2n) {
    border-right: 4px solid #EEF3F8;
  }
}
.spirit-maxim__item:last-child {
  border-right: 0;
}
.spirit-maxim__ttl {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 2;
  font-weight: 400;
  text-align: center;
  color: black;
  margin-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .spirit-maxim__ttl {
    font-size: 1.4rem;
    margin-bottom: 12px;
  }
}
.spirit-maxim__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 9px;
}
@media screen and (max-width: 834px) {
  .spirit-maxim__list {
    gap: 5.7px;
  }
}
.spirit-maxim__list-item {
  width: 152px;
  height: 152px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #EEF3F8;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  color: #003D7D;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .spirit-maxim__list-item {
    width: 86px;
    height: 86px;
    font-size: 1.2rem;
  }
}

.spirit-concept {
  background: #EEF3F8;
  padding: 161px 0 280px;
}
@media screen and (max-width: 834px) {
  .spirit-concept {
    padding: 53px 0 117px;
  }
}
.spirit-concept__ttl {
  margin-bottom: 41px;
}
@media screen and (max-width: 834px) {
  .spirit-concept__ttl {
    margin-bottom: 26px;
  }
}
.spirit-concept__brief {
  line-height: 2;
  letter-spacing: 1.6px;
}
@media screen and (max-width: 834px) {
  .spirit-concept__brief {
    letter-spacing: 1px;
  }
}

/* ====================================================
Module
==================================================== */
/* ====================================================
Pages
==================================================== */