@use "vars" as *;


.outline-equipment{
	&__brief{
		line-height: 2;
		color: black;
		margin-bottom: 33px;
	}
	.outline-article{
	    margin-bottom: 103px;
	}
	&__desc{
		margin-bottom: 60px;
		&-ttl{
			margin-bottom: 26px;
		}
		.c-txt__01{
			line-height: 2;
			color: black;
			letter-spacing: 1.5px;
			@include mq{
				@include fts(14, 28);
			}
		}
	}
	&__info{
		background: #EEF3F8;
		@include flex(between, 0, wrap);
		padding: 48px 80px;
		gap: 10px 0;
		@include mq{
			padding: 20px;
		}
		&-ttl{
			width: 165px;
			@include mq{
				width: 100%;
			}
		}
		.c-txt__01{
			line-height: 2;
			color: black;
			letter-spacing: 1.5px;
			width: calc(100% - 165px);
			a{
				text-decoration: underline
			}
			@include mq{
				@include fts(14, 28);
				width: 100%;
			}
		}
	}
}
.concept-list{
	&__item{
		@include mq(md-min){
			padding: 45px 0 70px;
		}
	}
}
