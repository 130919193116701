@use "vars" as *;

.sec-history{
	padding: 93px 0 240px;
	@include mq{
		padding: 23px 0 167px;
	}
	&__ttl{
		text-align: center;
		@include fts(60, 90);
		color: black;
		margin-bottom: 122px;
		@include mq{
			@include fts(33.49, 50.24);
			margin-bottom: 11px;
		}
		@include mq(xs){
			@include font-size(26);
		}
		>span{
			@include flex(center, center);
		}
		&-first{
			.c-ft--en{
				@include fts(48, 57.6);
				color: #003d7d;
				width: 382px;
				letter-spacing: 1px;
				position: relative;
				text-align: right;
				font-family: $font-en;
				margin-top: 18px;
				span{
					background: white;
					display: inline-block;
					position: relative;
					z-index: 2;
					padding-left: 10px;
				}
				@include mq{
					width: 218px;
					@include font-size(26.79);
					margin-top: 10px;
				}
				@include mq(xs){
					width: 154px;
				}
				@include p-before(181px, 4px){
					
					background: #003D7D;
					left: 42px;
					top: 41%;
					transform: translateY(-50%);
					@include mq{
						width: 100px;
						height: 1px;
						left: 27px;
					}
					@include mq(xs){
						width: 60px;
						left: 12px;
					}
				}
			}
		}
	}
	&__img{
		@include mq(sm){
			padding-left: 16px;
		}
	}
}
.history-box{
	position: relative;
	padding-top: 8.3%;
	padding-bottom: 16%;
	@include mq(sm-min){
		text-align: center;
		margin-bottom: 74px;
	}
	@include mq(sm){
		padding-top: 21.3%;
	}
	@include p-after(2px, 100%){
		background: linear-gradient(180deg, rgba(0, 61, 125, 0) 0%, #003D7D 10%, #003D7D 90%, rgba(0, 61, 125, 0) 100%);
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		@include mq(sm){
			left: 4.5%;
			width: 1px;
			transform: none;
		}
	}
}
.history-icon{
	text-align: center;
	@include mq(sm){
		img{
			width: 94px;
		}
	}
}
