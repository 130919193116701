@use "vars" as *;

/* ====================================================
Layout
==================================================== */



.mv-news {
	background: #003D7D;
	color: white;
	padding: 32px 0;

	@include mq {
		padding: 12px 0;
	}

	&__ttl {
		@include flex(between, center, wrap);
		@include fts(60, 60);

		@include mq(xl) {
			@include font-size(40);
		}

		@include mq() {
			@include font-size(16);
		}

		span {
			font-family: "A-OTF A1 Mincho Std", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
			;
		}

		small {
			@include font-size(24);

			@include mq() {
				@include font-size(12);
			}
		}
	}
}
.main-footer__copyright{
	@include mq {
		background: transparent;
	}
}
.news-tab {
	@include mq(md-min){
		min-height: 707px;
	}
	&__ttl {
		text-align: center;
		margin-bottom: 53px;

		@include mq {
			margin-bottom: 22px;
		}
	}

	&__box {
		margin-bottom: 34px;
		padding-bottom: 10px;
		border-bottom: 1px solid #003D7D;
		position: relative;

		@include mq(sm) {
			margin-bottom: 2px;
			border-bottom: 0;
		}
	}

	.c-btn__01 {
		margin: 0 auto;
		@include mq{
			background: transparent;
			border-color: transparent;
			max-width: 196px;
		}
	}

	&__choose {
		min-height: 52px;
		position: relative;
		@include flex(0, center);
		background: #003D7D;
		color: white;
		@include fts(16, 23);
		font-weight: 600;
		padding: 10px 20px;
		cursor: pointer;

		@include p-after(10px, 10px) {
			right: 23px;
			top: 43%;
			border-bottom: 1px solid white;
			border-right: 1px solid white;
			transform: translateY(-50%) rotate(45deg);
		}

		@include mq(sm-min) {
			display: none;
		}
	}

	&__link {
		@include mq(sm-min) {
			@include flex();
			overflow-x: auto;
		}

		@include mq(sm) {
			position: absolute;
			top: calc(100% - 20px);
			left: 0;
			width: 100%;
			// display: none;
			z-index: 10;

			height: 0;
			overflow: hidden;
			transition: all .3s ease-in;

			&.is-show {
				height: auto;
				border-top: 1px solid white;
			}
		}

		&-item {
			@include mq(sm) {
				border-bottom: 1px solid white;
			}

			@include mq(sm-min) {
				width: 25%;

				&:not(:last-child) {
					border-right: 1px solid #003D7D;
				}
			}

			&.active {
				a {
					background: #003D7D;
					color: white;
					min-height: 60px;

				}

				@include mq(sm) {
					display: none;
				}
			}

			a {
				@include flex(center, center);
				font-weight: 700;
				color: #003D7D;
				@include fts(24, 34);
				@include font-size(21);
				height: 100%;
				cursor: pointer;
				transition: all .3s ease-in;
				&:hover{
					@include mq(md-min){
						background: #003D7D;
						color: white;
						min-height: 60px;
					}
				}
				@include mq(xl) {
					@include font-size(16);
				}

				@include mq(sm) {
					// background: rgba($color: #003D7D, $alpha: .7);
					color: white;
					background: #003D7D;
					padding: 10px 20px;
					justify-content: start;
				}
			}
		}
	}

	&__content {
		&:not(.active) {
			display: none;
		}
		&-notitem{
			min-height: 350px;
			@include flex(center, center);
		}
	}
}

.news-list {
	@include fts(16, 32);
	margin-bottom: 39px;
	@include mq{
		margin-bottom: 7px;
	}
	&__item {
		border-bottom: 1px solid #FFFFFF;
		padding-bottom: 37px;
		@include mq{
			padding-bottom: 11px;
		}
		&:not(:last-child) {
			margin-bottom: 34px;
			@include mq{
				margin-bottom: 16px;
			}
		}
		a{
			transition: all .3s ease-in;
			&:hover{
				opacity: .7;
				&::after{
					right: 0;
				}
			}
		}
	}

	&__wrap {
		@include flex(between, 0, wrap);
		position: relative;
		
		@include mq(md-min){
			padding-right: 35px;
		}
		@include p-after(28px, 4px) {
			background: url(./common/img/icon-arrow-right-blue.png) no-repeat center/contain;
			right: 5px;
			top: 50%;
			transform: translateY(-50%);
			transition: all .3s ease-in;
			@include mq {
				display: none;
			}
		}
	}

	&__meta {
		@include flex(0, center, wrap);
		// width: 297px;
		width: 110px;
		gap: 10px 40px;

		@include mq {
			width: 100%;
			gap: 10px 21px;
			margin-bottom: 8px;
		}

		&-date {
			color: #777777;
			letter-spacing: 1px;
		}

		&-tag {
			@include flex(0, 0, wrap);
			@include fts(12, 17);

			span {
				border: 1px solid #003D7D;
				min-width: 70px;
				min-height: 24px;
				color: #003D7D;
				border-radius: 35px;
				@include flex(center, center);
				text-align: center;
			}
		}
	}

	&__ttl {
		// width: calc(100% - 297px);
		width: calc(100% - 110px);
		font-weight: 400;
		letter-spacing: 3.3px;

		@include mq {
			width: 100%;
			@include fts(14, 28);
		}

	}
}
