@use "vars" as *;

#page-rebuilding_index {
	padding-top: 0;
	position: relative;
    z-index: 11;
	
}

.mv-top {
	&__wrapper {
		max-width: 100%;
		height: auto;

		#card {
			overflow: hidden;
			// #card02{
			// 	transform: none;
			// }
			// #card01{
			// 	transform: none;
			// }
			// #card03{
			// 	transform: none;
			// }
		}
	}

	&__slide {
		&-item {
			img {
				width: 100%;
				transition: 3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			}

			&.slick-active {
				img {
					transform: scale(1.1, 1.1);
				}
			}
		}
	}

	&__ttl {
		top: auto;
		bottom: 75px;
		left: 124px;
		@include mq{
			margin-top: 62px;
		}
	}
}

.top-news {
	padding: 10.7% 0 160px;

	@include mq {
		// padding: 63px 0 60px;
		padding: 0 0 60px;
		margin: 63px 0 60px;
		background: #EEF3F8;
	}

	.news-tab {
		background: #EEF3F8;
		padding: 52px 80px 60px;

		@include mq(xl) {
			padding: 40px
		}

		@include mq {
			padding: 50px 20px 40px;
			
			margin: 0 -5vw;
		}
	}
}

.top-video {
	video {
		width: 100%;
	}
}
.news-categories{
	&__item{
		border-top: 1px solid #003D7D;
		&:last-child{
			border-bottom: 1px solid #003D7D;
		}
		a{
			display: block;
			color: #003D7D;
			position: relative;
			@include fts(16, 28);
			font-weight: 600;
			padding: 13px 35px 14px 20px;
			@include p-after(10px, 10px){
				border-top: 1px solid currentColor;
				border-right: 1px solid currentColor;
				top: 50%;
				right: 26px;
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
}

.trajectory-top {
	@include mq(md-min) {
		margin-bottom: 0;
		padding-bottom: 10%;
		padding-top: 55%;
		z-index: 4;
	}
	@include mq{
		padding-bottom: 42px;
	}
	&__list {
		@include mq(md-min) {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			max-width: 100%;
		}

		&:not(.trajectory-top__list--styles02) {
			@include mq(md-min) {

				li {
					position: absolute;

					&:nth-child(1) {
						width: 13.6%;
						top: -11.6vw;
						left: 8.9%;
					}

					&:nth-child(2) {
						width: 15.6%;
						left: 21.3%;
						top: .8%;
					}

					&:nth-child(3) {
						width: 18.9%;
						left: 51.2%;
						top: -8.5vw;
					}

					&:nth-child(4) {
						width: 10.6%;
						right: 15.6%;
						top: 12%;
						z-index: 2;
					}

					&:nth-child(5) {
						width: 15.2%;
						right: 2.6%;
						top: -9.8vw;
					}

					&:nth-child(6) {
						width: 22.2%;
						left: 4.5%;
						top: 30.2%;
					}
				}
			}
		}

		&--styles02 {
			@include mq(md-min) {
				// display: none;

				li {
					position: absolute;

					&:nth-child(1) {
						width: 17.6%;
						bottom: -6vw;
						left: 0;
					}

					&:nth-child(2) {
						width: 14.9%;
						left: 12.3%;
						bottom: -14.8vw;
					}

					&:nth-child(3) {
						width: 15.2%;
						left: 38.1%;
						bottom: -30.9vw;
					}

					&:nth-child(4) {
						width: 12.8%;
						right: 24.2%;
						bottom: -12vw;
						z-index: 2;
					}

					&:nth-child(5) {
						width: 21.2%;
						right: 2.6%;
						bottom: 15%;
					}

					&:nth-child(6) {
						width: 20.2%;
						right: 6%;
						bottom: -24.4vw;
					}
				}
			}
		}
	}
}

.plan-top {
	display: block;
	@include mq{
		margin-bottom: 79px;
	}
	&__ct{
		width: 100%;
		@include mq{
			padding: 46px 20px 52px;
		}
	}
	&__img{
		@include mq{
			margin-bottom: 55px;

		}
		&::before{
			@include mq{
				padding-top: 53.25%;
			}
		}
		img{
			@include mq{
				
			}
		}
	}
	@include mq(md-min) {
		margin-bottom: 0;
		padding-bottom: 35px;
		padding-top: 46%;
		flex-direction: column;
        position: relative;
        z-index: 3;
		
		&__box{
		    margin: 0 auto -21vw;
		}
		&__ct {
			// max-width: 505px;
			text-align: center;
			margin: 0 auto 76px;
			width: 100%;
			padding: 0;

			// color: white/
			.c-txt__01,
			.c-ttl__01 {
				color: white;
			}
			&-ttl{
			    margin-bottom: 34px;
			}

		}

		&__img {
			max-width: 505px;
			width: 100%;
			margin: 0 auto 7px;
			img{
			    height: 86%;
			}
		}

		.c-btn__01 {
			margin: 0 auto;
			background: white;
			color: #003D7D;
			border-color: white;
			width: 100%;
			text-align: left;

			&:after {
				background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
			}
		}
	}

	&__ct-desc {
		margin-bottom: 0;
	}

	.c-btn__01 {
		width: 100%;
		margin: 0 auto;
	}
}

.c-btn__01--whitesp {
	@include mq(md-min) {
		margin: 0 auto;
		background: white;
		color: #003D7D;
		border-color: white;
		width: 100%;
		text-align: left;

		&:after {
			background: url(../common/img/icon-arrow-right-blue.png) no-repeat center/contain;
		}
	}
	@include mq{
		@include font-size(16);
		font-weight: 700;
	}
}

.rebuilding-brn {
	position: fixed;
	bottom: 49px;
	right: 24px;
	z-index: 10;
}

.about-top {
	@include mq(md-min) {
		clip-path: none;
		padding-top: 33vw;
        padding-bottom: 13.5vw;
		background: transparent;
		// color: white;
		&__ttl{
		    margin-bottom: 38px;
		}
		&__desc{
			color: white;
		}
	}
	@include mq{
		padding: 64px 0 64px;
	}
	.c-btn__01 {
		margin: 0 auto;
	}
}
.building-top{
	@include mq(){
		padding: 110px 0 102px;
	}
	&__block {
		text-align: center;
		width: 100%;
	
		.c-btn__01 {
			margin: 0 auto;
		}
	}
}
.building-top,
.trajectory-top,
.plan-top,
.about-top {

	// @include mq(md-min) {
	// 	background: url(../img/rebuilding/bg-01.jpg) no-repeat center/cover;
	// 	.c-ttl__01{
	// 		color: white;
	// 	}
	// }
}
.sec-video{
	position: relative;
	z-index: 10;
	&__video{
		position: sticky;
		z-index: -1;
		width: 100%;
		height: 100vh;
		left: 0;
		top: 0;
		overflow: hidden;
		transition: all 1s;
		transform-origin: bottom;
		@include mq(sm){
			display: none;
		}
		video{
			width: 100%;
			height: 100%;
			min-height: 100vh;
			object-fit: cover;
			transition: all 1s;
			transform-origin: center;
		}
	}
	@include mq(md-min){
		.c-ttl__01--ff, .c-ttl__01{
			color: white;
		}
	}
}
#main-footer.sec-video{
	position: relative;
	
	.main-footer__img{
		@include mq(sm-min){
			// position: relative;
			// z-index: -2;
			// opacity: 0;
		}
	}
}
